import getCountry from '@/utils/get-country';
import { injectQueryParamsToUrl } from '@/utils/query-params';

const FALLBACK_ONBOARDING_URL = '/waiting-list?client=';

function getClientUrlOrFallback(client, country, programType) {
  // Inactive client: return coming soon or fallback wailist
  if (!client.isActive) {
    return FALLBACK_ONBOARDING_URL + client.clientKey;
  }

  // Single-region client: return onboardingUrl or create one
  if (!client?.countries?.length) {
    return createOnboardingURL({
      clientKey: client.institutionKey || client.clientKey,
      programType,
      countryCode: client.country?.data?.attributes?.ISOCode.toUpperCase(),
    });
  }

  // Multi-region client: get the selected country or fallback to client
  const clientCountry = getClientCountry(client, country);

  if (clientCountry && clientCountry.waitingList) {
    return FALLBACK_ONBOARDING_URL + client.clientKey;
  }

  const resp =
    clientCountry?.url ||
    createOnboardingURL({
      clientKey: client.institutionKey || client.clientKey,
      countryCode: getCountry(clientCountry?.country).key,
      programType,
    });

  return resp;
}

function getClientCountry(client, country) {
  if (country) {
    return client.countries.find((item) => getCountry(item.country).key === country.key);
  }

  // Fallback to first client country
  return client.countries.find(
    (item) => getCountry(item.country).label === client.countries[0].country,
  );
}

function getOnboardingURL({ client, country, programType, query }) {
  return injectQueryParamsToUrl(getClientUrlOrFallback(client, country, programType), {
    ...query,
    client: client.clientKey,
  });
}

function createOnboardingURL({ clientKey, countryCode, programType, query, triage }) {
  const code = mapCountryCode(countryCode);

  let URL = 'https://onboarding';

  if (process.env.NEXT_PUBLIC_ENV === 'staging') {
    URL = 'https://team-engagement-onboarding.staging';
  }
  URL += code && code !== 'us' ? `.${code.toLowerCase().trim()}` : '';
  URL += `.swordhealth.com/c/${clientKey}`;

  let params = { ...query };
  if (triage) params['triage_flow'] = 1;
  if (programType) params['program_type'] = programType;

  params['hide_get_started'] = true;

  return injectQueryParamsToUrl(URL, params);
}

function generateURL(url, params, countryCode = false) {
  const code = mapCountryCode(countryCode);
  let baseUrl = url;
  const queryParams = new URLSearchParams(params);

  if (code) {
    const splitUrl = baseUrl.split('.');
    const updatedUrl = splitUrl.toSpliced(1, 0, code);
    baseUrl = updatedUrl.join('.');
  }

  const loginUrl = new URL(baseUrl);

  queryParams.forEach((value, key) => {
    if (value !== undefined && value !== null && value !== 'null') {
      loginUrl.searchParams.append(key, value);
    }
  });

  return loginUrl.toString();
}

function mapCountryCode(countryCode) {
  if (!countryCode) {
    return 'us';
  }

  countryCode = countryCode.toUpperCase();

  if (countryCode === 'EU' || countryCode === 'PT') {
    return 'eu';
  }
  if (countryCode === 'CA' || countryCode === 'CA_EN' || countryCode === 'CA_FR') {
    return 'ca';
  }
  if (countryCode === 'CR' || countryCode === 'PR' || countryCode === 'US') {
    return 'us';
  }

  return countryCode.toLowerCase();
}

function createSignInURL(url, params, countryCode) {
  if (countryCode === 'US') {
    return generateURL(url, params, 'us');
  }
  if (countryCode === 'UK') {
    return generateURL(url, params, 'uk');
  }
  if (countryCode === 'AU') {
    return generateURL(url, params, 'au');
  }
  if (countryCode === 'EU' || countryCode === 'PT') {
    return generateURL(url, params, 'eu');
  }
  if (countryCode === 'CR' || countryCode === 'PR') {
    return generateURL(url, params);
  }
  if (countryCode === 'CA' || countryCode === 'CA_EN' || countryCode === 'CA_FR') {
    return generateURL(url, params, 'ca');
  }
  return generateURL(url, params);
}

function createGuardaLink({ clientKey, programType, serviceApp }) {
  let URL = 'https://guarda.page.link/naxz';

  if (serviceApp)
    URL =
      'https://guarda.page.link/?link=' +
      `${process.env.NEXT_PUBLIC_DEPLOYMENT_URL}` +
      '/' +
      programType +
      '/' +
      clientKey +
      '?t%3D' +
      serviceApp +
      '%23AppSupport&apn=com.swordhealth.guarda&ibi=com.swordhealth.guarda.ios&isi=1468523447';

  return URL;
}

export { getOnboardingURL, createOnboardingURL, createSignInURL, createGuardaLink };
